
<template>
   <div  v-editable="editing && blok" class="sb-group" ref="animation_target" :class="layout_classes ">
    <div class="group-visual-label" v-if="blok.use_label && blok.visual_label != '' && blok.visual_label" v-html="renderRichText(blok.visual_label)"></div>
      <div class="sb-group__inner">      
          <div class="group-label" v-if="blok.use_label && blok.label != ''">{{ blok.label }}</div>
          <StoryblokComponent v-for="blok,k in blok.content " :key="k" :blok="blok" />

      
      </div>
   </div>
</template>

<script setup>
const props = defineProps({ blok: Object })
const { getLayoutClasses } = useLayoutControl()
const {editing} = await useSettings()
const layout_classes = getLayoutClasses(props.blok)
// console.log(props)
// console.log(props.blok.visual_label)
const animation_target = ref(null)
  const {icons,uiconfig,handleAnimation} = useUIControl()
onMounted(() => {

  if(props.blok.layout?.a_enabled)handleAnimation(animation_target,{layout:props.blok.layout,type:'group',a:{...props.blok.layout.animation}});
})
</script>
<style lang="scss">
  .sb-group{
    // margin-bottom:1.75rem;
    .sb-group__inner{
      // height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
        //   >*{
        //   &:not(:last-child){

        //     margin-bottom:3rem;
        //   }
        // }
        // @include breakpoint(lg){
        //   >*{
        //   &:not(:last-child){

        //     margin-bottom:6rem;
        //   }
        // }
        // }
    }
    &.align-full{
      .sb-group__inner{
        align-items: inherit;
      }
    }
    .group-label{
      width: 100%;
    }
    .group-visual-label{
      position: absolute;
      top:0px;
      // top:0px;
    }
  }
</style>